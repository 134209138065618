import styled from "styled-components";

export const TextArea = styled.textarea`
    width: 100%;
    height: 154px;
    padding: 10px 16px 9px;
    border: 1px solid #e6e6e6;
    outline: none;
    color: #a6a6a6;
    &:focus{
        outline: 2px solid #0e6faa !important;
    }
    &.error{
        outline: 1px solid #f00;
    }
`;
export const ErrorMessage = styled.div`
    background-color: #ffd9d9;
    color: #f00;
    margin: 30px 0 10px;
    padding: 18px 30px 18px 20px;
    font-size: 14px;
    border: none;
    width: 100%;
`;
