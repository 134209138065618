import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import ContactUs from "../components/OurCompany/ContactUs/ContactUs";

import Favicon from "../images/Favicon.png";

const ContactUsPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Contact Us - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Contact Drivosity for information on our advanced telematics solution for last-mile delivery. Geared to improve safety and productivity for your fleet management and first-party delivery, Reach out to our sales, support, or general inquiries teams. We're here to help your business thrive. Get in touch today."/>
            </Helmet>
            <Layout component={<ContactUs/>}/>
        </>
    )
}

export default ContactUsPage;
